import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, List, ListItem, Modal } from '@material-ui/core';
import { MdClear } from 'react-icons/md';

const CopyrightModal = ({ open, setOpen }) => {
  const onClose = () => {
    setOpen(!open);
  };
  const copyright = [
    'Powered by Strateg.In',
    'Kyle Head on Unsplash',
    'Alberto Bigoni on Unsplash',
    'Leon Liu on Unsplash',
    'Ricardo Moura on Unsplash',
    'Kiko Photos / Shutterstock.com',
    'Stockbym / Shutterstock.com',
    'Pack-Shot / Shutterstock.com',
    'Anton_Ivanov / Shutterstock.com',
    'Tang Yan Song / Shutterstock.com'
  ];

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="modal copyright-modal small"
    >
      <div className="modal-content">
        <h2 className="modal-title">Copyright</h2>
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>
        <List>
          {copyright.map((el) => (
            <ListItem key={el}>{el}</ListItem>
          ))}
        </List>
      </div>
    </Modal>
  );
};

export default CopyrightModal;

CopyrightModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

CopyrightModal.defaultProps = {
  open: undefined
};
