import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@material-ui/core';
import { MdClear } from 'react-icons/md';
import { withTrans } from '../../i18n/withTrans';

const ModalGroup = ({ t, open, setOpen }) => {
  const onClose = () => {
    setOpen(!open);
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="modal group-prices-modal"
    >
      <div className="modal-content">
        <h2 className="modal-title">{t('footer.groupPrices')}</h2>
        <span style={{ fontStyle: 'italic', fontSize: 14 }}>
          {t('groupModal.subTitle2')}
        </span>
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>
        <div style={{ position: 'relative' }}>
          <div className="table-title-group-prices group-title-rotate">
            <p style={{ fontWeight: 'bold' }}>{t('groupModal.title')}</p>
            <span style={{ fontSize: 14, fontStyle: 'italic' }}>
              {t('groupModal.subTitle')}
            </span>
          </div>
          <table className="group-prices-table">
            <thead>
              <tr>
                <th></th>
                <th className="table-title-group-prices">
                  {t('groupModal.prices')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('groupModal.fullPrice.title')}</td>
                <td>{t('groupModal.fullPrice.price')}</td>
              </tr>
              <tr>
                <td>
                  {t('groupModal.reducedPrice.title')} <br />
                  <span>{t('groupModal.reducedPriceDescription')}</span>
                </td>
                <td>{t('groupModal.reducedPrice.price')}</td>
              </tr>
              <tr>
                <td>{t('groupModal.childPrice.title')}</td>
                <td>{t('groupModal.childPrice.price')}</td>
              </tr>
              <tr>
                <td>
                  {t('groupModal.nightPrice.title')} <br />
                  <span>{t('groupModal.nightPriceDescription')}</span>
                </td>
                <td>{t('groupModal.nightPrice.price')}</td>
              </tr>
            </tbody>
          </table>
          <strong>{t('groupModal.professionalInfo')}</strong>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(ModalGroup);

ModalGroup.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

ModalGroup.defaultProps = {
  open: undefined
};
