import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'fr',
    // have a common namespace used around the full app
    resources: {
      fr: {
        translations: require('../locales/fr-FR/translations.json')
      },
      en: {
        translations: require('../locales/en-EN/translations.json')
      },
      es: {
        translations: require('../locales/es-ES/translations.json')
      }
    },
    ns: ['translations'],
    defaultNS: 'translations',
    debug: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }
  });
i18next.languages = ['fr', 'en', 'es'];

export default i18next;
